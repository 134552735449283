import { combineReducers } from 'redux';

import { AnnouncementState } from './announcement/AnnouncementState';
import { AuthorizationState } from './authorization/AuthorizationState';
import { ChapterState } from './chapter/ChapterState';
import { ContinueWatchingState } from './continue-watching/ContinueWatchingState';
import { CorrectAndIncorrectState } from './correct-and-incorrect-overlay/CorrectAndIncorrectState';
import { FbModule } from './facebookModule/FbModule';
import { FilterState } from './filter/FilterState';
import { GradeState } from './grade/GradeState';
import { LessonListState } from './lesson-list/LessonListState';
import { LessonSetListState } from './lesson-list/LessonSetListState';
import { LiveQuizPercentageState } from './live-quiz-percentage/LiveQuizPercentageState';
import { LiveStreamHistoryRecentState } from './live-stream-history/LiveStreamHistoryRecentState';
import { LiveStreamHistoryState } from './live-stream-history/LiveStreamHistoryState';
import { LiveStreamTableListState } from './live-stream-table-list/LiveStreamTableListState';
import { MiniTestState } from './mini-test/MiniTestState';
import { NoticeBoardState } from './notice-board/NoticeBoardState';
import { PaymentManagementState } from './payment-management/PaymentManagement';
import { PlaybackState } from './playbackType/PlaybackTypeState';
import { ProfileState } from './profile/ProfileState';
import { QuaterExamListState } from './exam/ExamListState';
import { SearchState } from './lesson-list/SearchState';
import { ShopeePayState } from './shopee-pay/ShopeePayState';
import { SkipQuestionState } from './skip-question/SkipQuestion';
import { SubjectState } from './subject/SubjectState';
import { TransactionState } from './transaction/Transaction';



export default combineReducers<NaTypes.Store>({
    announcement: AnnouncementState,
    authenticate: AuthorizationState,
    chapter: ChapterState,
    continueWatching: ContinueWatchingState,
    correctAndIncorrectOverlay: CorrectAndIncorrectState,
    facebookModule: FbModule,
    filter: FilterState,
    grade: GradeState,
    lessonList: LessonListState,
    lessonSetList: LessonSetListState,
    liveQuizPercentage: LiveQuizPercentageState,
    liveStreamHistory: LiveStreamHistoryState,
    liveStreamHistoryRecent: LiveStreamHistoryRecentState,
    liveStreamTableList: LiveStreamTableListState,
    miniTest: MiniTestState,
    noticeBoard: NoticeBoardState,
    paymentManagement: PaymentManagementState,
    playbackType: PlaybackState,
    profile: ProfileState,
    quarterExams: QuaterExamListState,
    searchText: SearchState,
    shopeePay: ShopeePayState,
    skipQuestion: SkipQuestionState,
    subject: SubjectState,
    transaction: TransactionState,
});
