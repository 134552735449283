const initialState: NaTypes.CorrectAndIncorrectOverlay = { show: false };

const SHOW_CORRECT_OVERLAY = 'SHOW_CORRECT_OVERLAY';
const SHOW_INCORRECT_OVERLAY = 'SHOW_INCORRECT_OVERLAY';
const CLOSE_OVERLAY = 'CLOSE_OVERLAY';

const showCorrect = () => ({ type: SHOW_CORRECT_OVERLAY });
const showIncorrect = () => ({ type: SHOW_INCORRECT_OVERLAY });
const close = () => ({ type: CLOSE_OVERLAY });

export { showCorrect, showIncorrect, close };

export function CorrectAndIncorrectState(state: NaTypes.CorrectAndIncorrectOverlay = initialState, action: any): NaTypes.CorrectAndIncorrectOverlay {
    switch (action.type) {
        case SHOW_CORRECT_OVERLAY:
            return { ...state, show: true, correct: true };
        case SHOW_INCORRECT_OVERLAY:
            return { ...state, show: true, correct: false };
        case CLOSE_OVERLAY:
            return { ...state, show: false };
        default:
            return state;
    }
}
